<template>
  <div class="container" v-cloak>
    <div class="row">
      <div class="col-12 py-5 px-1">
        <div class="text-center">
          <i class="icon icon-check"></i>
          <h3>Thank you for purchasing!</h3>
          <h5 v-if="isOftenPay">
            You can query licenses with the crypto wallet address just used!
          </h5>
          <h5 v-else>License has been sent to your PayPal email address!</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PurchaseSuccess",
  data() {
    return {
      paid_from_address: this.$route.query.paid_from_address,
      isOftenPay: !!this.$route.query.paid_from_address,
    };
  },
  created() {
    let query;
    if (this.isOftenPay) {
      query = {
        paid_from_address: this.paid_from_address,
      };
    } else {
      query = {};
    }

    setTimeout(() => {
      this.$router.replace({
        name: "manage",
        query,
      });
    }, 3000);
  },
  computed: {},
};
</script>

<style scoped lang="scss">
</style>
